import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    rag: {},
}

const ragSlice = createSlice({
    name: "rag",
    initialState,
    reducers: {
        setRag: (state, action) => {
            console.log("action.payload",action.payload)
            state.rag = action.payload
        }
    },
})

export const { setRag } = ragSlice.actions
export default ragSlice.reducer